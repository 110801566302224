import React, { Component } from 'react'
import axios from 'axios'
import { BodyText, Button, Content, FieldGroup, Form, Heading, LoadingSpinner, Notification } from '@lmig/lmds-react'
import PaymentInformation from './FormGroup/PaymentInformation'
import AdditionalComments from './FormComponents/AdditionalComments'
import ContactInfo from './FormGroup/ContactInfo'
import Address from './FormGroup/Address'
import CheckBoxes from './FormGroup/CheckBoxes'
import ESign from './FormComponents/ESign'
import SupportingDocs from './FormGroup/SupportingDocs'
import Error from './FormError/Error'
import './FormCSS/Form.css'

class RequestReplacementCheck extends Component {
  state = {
    customerTicketNumber: '',
    hasInvalidFields: false,
    missingFields: [],
    attachmentList: [],
    isError: false,
    isLoading: false,
    loadingStatus: '',
    requestReplacementCheck: {
      propertyID: '',
      issueDate: '',
      paymentAmount: '',
      payeeName: '',
      address1: '',
      address2: '',
      city: '',
      zipcode: '',
      state: '',
      country: 'United States',
      requestersName: '',
      phoneNumber: '',
      email: '',
      comments: '',
      esign: '',
      marriedDivorce: {
        isChecked: false,
        newName: '',
      },
      payeeDeath: {
        isChecked: false,
      },
      nameRemovedDeath: {
        isChecked: false,
        removeName: '',
        includeName: '',
      },
      removeBusiness: {
        isChecked: false,
        removeBusinessName: '',
      },
    },
  }

  // handles all state changes outside of the checkboxes, payeeNames, and attachments
  onChangeValueHandler = val => {
    let inputName = val.target.name
    let inputVal = val.target.value
    let requestState = this.state.requestReplacementCheck
    if (requestState.hasOwnProperty(inputName)) {
      if (inputName === 'paymentAmount') {
        requestState[inputName] = inputVal
        this.setState({ requestReplacementCheck: requestState })
      }
      if (inputName === 'issueDate') {
        let regex = /^(0?[1-9]|1[0-2])[(?:/|\-|.)](0?[1-9]|1\d|2\d|3[01])[(?:/|\-|.)]((19|20)\d{2})$/
        let found = inputVal.match(regex)
        let newDate = inputVal
        if (found != null) {
          if (found[2].length === 1) {
            found[2] = '0' + found[2]
          }
          newDate = found[3] + '-' + found[1] + '-' + found[2]
        }
        requestState[inputName] = newDate
        this.setState({ issueDate: requestState })
      } else {
        requestState[inputName] = inputVal
        this.setState({ requestReplacementCheck: requestState })
      }
    }
  }

  // handles payeeName state change
  onChangePayeeName = val => {
    let requestState = this.state.requestReplacementCheck
    requestState['payeeName'] = val.toString()
    this.setState({ requestReplacementCheck: requestState })
  }

  // handles checkboxes state
  onChangeCheckBoxContentHandler = (name, val) => {
    if (name === 'mdState') {
      let mdState = this.state.requestReplacementCheck.marriedDivorce
      mdState.isChecked = val.isChecked
      mdState.newName = val.newName
      this.setState({ marriedDivorce: mdState })
    } else if (name === 'payeeDeceased') {
      let payDeath = this.state.requestReplacementCheck.payeeDeath
      payDeath.isChecked = val.isChecked
      this.setState({ payeeDeath: payDeath })
    } else if (name === 'nameRemoveDeath') {
      let nameDeathState = this.state.requestReplacementCheck.nameRemovedDeath
      nameDeathState.isChecked = val.isChecked
      nameDeathState.includeName = val.includeName.toString()
      nameDeathState.removeName = val.removeName.toString()
      this.setState({ nameRemovedDeath: nameDeathState })
    } else if (name === 'removeBusiness') {
      let removeNameState = this.state.requestReplacementCheck.removeBusiness
      removeNameState.isChecked = val.isChecked
      removeNameState.removeBusinessName = val.removeBusinessName.toString()
      this.setState({ removeBusiness: removeNameState })
    } else {
    }
  }

  //handles submit, checks if required fields are empty before populating tickets
  handleSubmit = () => {
    this.validateAllFields()
    if (this.state.missingFields.length !== 0) {
      this.setState({ hasInvalidFields: true })
      window.scroll(0, 0)
    } else {
      this.populateTicket()
      this.setState({ hasInvalidFields: false })
    }
  }

  //validates which fields need to be addressed
  validateAllFields() {
    this.setState({ hasInvalidFields: false })
    this.state.missingFields = []
    if (this.state.requestReplacementCheck.propertyID === '') {
      this.state.missingFields.push('PropertyID/Payment #/Replacement Check # needed')
    }
    if (this.state.requestReplacementCheck.address1 === '') {
      this.state.missingFields.push('Street Address is needed')
    }
    if (this.state.requestReplacementCheck.city === '') {
      this.state.missingFields.push('City is needed')
    }
    if (this.state.requestReplacementCheck.zipcode === '') {
      this.state.missingFields.push('Zipcode is needed')
    }
    if (this.state.requestReplacementCheck.state === '') {
      this.state.missingFields.push('State is needed')
    }
    if (this.state.requestReplacementCheck.requestersName === '') {
      this.state.missingFields.push('Requester name is needed')
    }
    if (this.state.requestReplacementCheck.phoneNumber === '') {
      this.state.missingFields.push('Telephone number is needed')
    }
    if (
      this.state.requestReplacementCheck.phoneNumber !== '' &&
      this.state.requestReplacementCheck.phoneNumber.length !== 14
    ) {
      this.state.missingFields.push('Phone number in incomplete.')
    }
    if (this.state.requestReplacementCheck.esign === '') {
      this.state.missingFields.push('E-signature is needed')
    }
    if (
      this.state.requestReplacementCheck.marriedDivorce.isChecked === true &&
      this.state.requestReplacementCheck.marriedDivorce.newName === ''
    ) {
      this.state.missingFields.push('Please enter new married name or uncheck box below')
    }
    if (
      this.state.requestReplacementCheck.nameRemovedDeath.isChecked === true &&
      this.state.requestReplacementCheck.nameRemovedDeath.includeName === '' &&
      this.state.requestReplacementCheck.nameRemovedDeath.removeName === ''
    ) {
      this.state.missingFields.push(
        'Please enter a name to be added/the deceased individual to be removed or uncheck box below',
      )
    }
    if (
      this.state.requestReplacementCheck.removeBusiness.isChecked === true &&
      this.state.requestReplacementCheck.removeBusiness.removeBusinessName === ''
    ) {
      this.state.missingFields.push('Please enter business to be removed or uncheck box below')
    }
    if (
      this.state.requestReplacementCheck.email !== '' &&
      this.isValidEmail(this.state.requestReplacementCheck.email) === false
    ) {
      this.state.missingFields.push('Please enter a valid email with @ symbol.')
    }
    if (
      this.state.requestReplacementCheck.issueDate !== '' &&
      this.isValidDate(this.state.requestReplacementCheck.issueDate) === false
    ) {
      this.state.missingFields.push('Please enter a valid payment issue date.')
    }

    this.state.requestReplacementCheck.paymentAmount = this.state.requestReplacementCheck.paymentAmount.replace('$', '')
  }

  isValidEmail(emailPassed) {
    const re = /\S+@\S+\.\S+/
    return re.test(emailPassed)
  }

  isValidDate(datePassed) {
    const re = /^((19|20)\d{2})-(0?[1-9]|1[0-2])-(0[1-9]|1\d|2\d|3[01])$/
    return re.test(datePassed)
  }

  // this will create a ticket and pass the Jira Ticket ID to the upload attachments method, if there are attachments
  populateTicket() {
    let postUrl = '/api/createJiraTicket/RRC'
    const formData = new FormData()
    //add customer ticket number
    formData.append('requestData', JSON.stringify(this.state.requestReplacementCheck))
    //add files to data form
    this.state.attachmentList.map(aFile => formData.append('files', aFile))

    this.setState({ isLoading: true, loadingStatus: 'Creating request...' })
    axios({
      method: 'post',
      url: postUrl,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' },
    })
      .then(result => {
        if (result.status === 201 || result.status === 200) {
          this.setState({ isLoading: false, loadingStatus: '' })
          this.putTicketInState(result.data.key)
        } else {
          // error occurred that isn't a network error
          this.setState({ isLoading: false, loadingStatus: '', isError: true })
        }
      })
      .catch(error => {
        this.setState({ isLoading: false, loadingStatus: '', isError: true })
        console.error('error: ', error)
      })
  }

  extractTicketNumber(ticket) {
    const ticketRegex = /[a-zA-Z]+-([0-9]+)/
    return ticketRegex.exec(ticket)?.[1]
  }

  putTicketInState(ticketNumber) {
    this.setState({ customerTicketNumber: ticketNumber })
    window.open('/confirmationPage/0/' + this.extractTicketNumber(this.state.customerTicketNumber), '_self')
  }

  //adds attachments to list
  handleAttachments = filesToPass => {
    this.setState({ attachmentList: filesToPass })
  }

  // handle state change from the closeModal function in Error.js
  handleErrorPopup = isOpen => {
    this.setState({ isError: isOpen })
  }

  render() {
    let listOfMVs = this.state.missingFields.map((v, index) => (
      <li className='notification-bullet' key={index}>
        {v}
      </li>
    ))
    return (
      <>
        {this.state.isError && <Error isError={this.state.isError} update={this.handleErrorPopup} />}
        {this.state.isLoading && <LoadingSpinner />}
        <Content className={'main'}>
          <Heading type={'h3-bold'}>Request Replacement Check</Heading>
          <BodyText type={'lead'}>Request a replacement check based on an Unclaimed Property Letter.</BodyText>
          <Form>
            {this.state.hasInvalidFields && (
              <Notification className='notification-window' type='error'>
                <h6>Please address the following issues:</h6>
                <br />
                <ul>{listOfMVs}</ul>
              </Notification>
            )}
            <br />
            <FieldGroup id='fieldgroup--differentInquiryType' disableMargins='top'>
              <div className='formBorder'>
                <div className='borderMargin'>
                  <PaymentInformation
                    payeeNameChange={this.onChangePayeeName}
                    onChangeHandler={this.onChangeValueHandler}
                  />
                  <br />
                  <Address onChangeHandler={this.onChangeValueHandler} />
                  <br />
                  <ContactInfo onChangeHandler={this.onChangeValueHandler} />
                  <br />
                  <CheckBoxes onChangeCheckBoxContents={this.onChangeCheckBoxContentHandler} />
                  <br />
                  <SupportingDocs
                    attachmentsList={this.handleAttachments}
                    maxFileSize={'20MB'}
                    maxTotalFileSize={'400MB'}
                  />
                  <br />
                  <AdditionalComments onChangeHandler={this.onChangeValueHandler} />
                  <br />
                  <ESign onChangeHandler={this.onChangeValueHandler} />
                  <br />
                  <br />
                  <Button variant='primary' size='large' onClick={this.handleSubmit} disabled={this.state.isLoading}>
                    {this.state.isLoading ? this.state.loadingStatus : 'Submit Request'}
                  </Button>
                </div>
              </div>
            </FieldGroup>
          </Form>
        </Content>
      </>
    )
  }
}

export default RequestReplacementCheck
